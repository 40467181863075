<template>
  <div>
    <b-modal
      id="modal-media-plan"
      ref="modal-media-plan"
      scrollable
      size="xl"
      :title="getTitle()"
      :ok-disabled="$v.$invalid || isLoadingModal"
      :ok-title="modalName === 'add' ? $t('table.add') : $t('table.save')"
      :cancel-title="$t('table.cancel')"
      :hide-footer="!canEdit || (modalName === 'edit' && isAutomatic && !canEditAutomaticPlan && (!allowAMAEditMPCWithFixedGrpType || selects.close))"
      @ok.prevent="showModalMediaPlanConfirm"
    >
      <SpinnerLoader v-if="isLoadingModal" :loading="isLoadingModal === true ? 'loading' : 'success'" />
      <form v-else @submit.stop.prevent="checkIfValidAddEditThenEnter">
        <input type="submit" value="Submit" class="hidden-submit" />
        <div class="d-flex justify-content-between w-100 mb-2">
          <div class="col-auto pl-0">
            <label>{{ $t('table.agency') }}:</label>
            {{ selects.agency ? selects.agency.name : '' }}
          </div>
          <div class="col-auto text-center">
            <label>{{ $t('table.project') }}:</label>
            {{ projectName }}
          </div>
          <div class="col-auto text-right pr-0">
            <label>{{ $t('table.order') }}:</label>
            {{ orderName }}
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <b-form-group style="width: 49%" :label="$t('table.mediaPlanEnter')" label-for="input-3-name">
            <b-form-input
              id="input-3-name"
              v-model="selects.mediaPlan"
              type="text"
              :placeholder="$t('table.mediaPlanEnter')"
              required
              autofocus
              :disabled="(modalName === 'edit' && selects.close) || !canEdit || (modalName === 'edit' && isAutomatic && !canEditAutomaticPlan)"
            ></b-form-input>
          </b-form-group>
          <b-form-group style="width: 49%" :label="$t('table.selectChannel')">
            <MultiSelect
              v-model="selects.channel"
              :options="orderChannelsList"
              :placeholder="$t('table.selectChannel')"
              label="name"
              track-by="id"
              data-automatic="ms-channel"
              :disabled="
                (modalName === 'edit' && selects.close) ||
                !canEdit ||
                (modalName === 'edit' && isAutomatic && !canEditAutomaticPlan) ||
                canEditOnlyNameAndCommercials
              "
              @input="selectedChannel()"
            ></MultiSelect>
          </b-form-group>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <b-form-group style="width: 49%" :label="$t('table.selectType')">
            <MultiSelect
              v-model="selects.type"
              :options="placementTypeWithoutAll"
              :placeholder="$t('table.selectType')"
              label="name"
              track-by="id"
              data-automatic="ms-placement-type"
              :disabled="
                (modalName === 'edit' && selects.close) ||
                !canEdit ||
                (modalName === 'edit' && isAutomatic && !canEditAutomaticPlan) ||
                canEditOnlyNameAndCommercials ||
                blockAMAChangeMPType
              "
              @input="selectedType()"
            ></MultiSelect>
          </b-form-group>
          <b-form-group style="width: 49%" :label="$t('table.selectCommercialType')">
            <MultiSelect
              v-model="selects.commercial"
              :options="commercialTypeWithoutAll"
              :placeholder="$t('table.selectCommercialType')"
              label="name"
              track-by="id"
              data-automatic="ms-commercial-type"
              disabled
            ></MultiSelect>
          </b-form-group>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <b-form-group :style="canEditAutomaticPlan && !isAMA ? { width: '33%' } : { width: '49%' }" :label="$t('channelModal.measurements')">
            <MultiSelect
              v-model="selects.measurement"
              :options="measurementsList"
              :placeholder="$t('channelModal.measurements')"
              label="name"
              track-by="id"
              data-automatic="ms-measurement"
              :disabled="
                (modalName === 'edit' && selects.close) ||
                ((!canEdit || (modalName === 'edit' && isAutomatic && !canEditAutomaticPlan) || !isCrossChannel || canEditOnlyNameAndCommercials) && !isAdmin)
              "
              @input="selectedMeasurement()"
            ></MultiSelect>
          </b-form-group>
          <b-form-group :style="canEditAutomaticPlan && !isAMA ? { width: '33%' } : { width: '49%' }" :label="$t('channelModal.target')">
            <MultiSelect
              v-model="selects.target"
              :options="targetList"
              :placeholder="$t('channelModal.target')"
              label="name"
              track-by="id"
              data-automatic="ms-target"
              :disabled="
                (modalName === 'edit' && selects.close) ||
                ((!canEdit || (modalName === 'edit' && isAutomatic && !canEditAutomaticPlan) || !isCrossChannel || canEditOnlyNameAndCommercials) && !isAdmin)
              "
            ></MultiSelect>
          </b-form-group>
          <b-form-group v-if="canEditAutomaticPlan && !isAMA" class="pt-4 mt-1" style="width: 30%">
            <b-form-checkbox
              id="checkbox-notMoreThanOneCommercial"
              v-model="selects.oneCommercialInBlock"
              name="checkbox-notMoreThanOneCommercial"
              class="custom-control-inline"
              :disabled="!canEdit || (modalName === 'edit' && selects.close) || canEditOnlyNameAndCommercials"
            >
              {{ $t('table.notMoreThanOneCommercial') }}
            </b-form-checkbox>
          </b-form-group>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <b-form-group style="width: 49%" :label="$t('table.writeDateFrom')" label-for="datepicker-buttons-from">
            <datepicker-wrapper
              id="datepicker-buttons-from"
              v-model="selects.start"
              required
              :min="minDate"
              :max="maxDate"
              :disabled="
                (modalName === 'edit' && selects.close) ||
                !canEdit ||
                (modalName === 'edit' && isAutomatic && !canEditAutomaticPlan) ||
                canEditOnlyNameAndCommercials ||
                blockAMAChangeAutomatonPeriod
              "
            />
          </b-form-group>
          <b-form-group style="width: 49%" :label="$t('table.writeDateTo')" label-for="datepicker-buttons-to">
            <datepicker-wrapper
              id="datepicker-buttons-to"
              v-model="selects.end"
              required
              :min="getMaxDate(selects.start, minDate)"
              :max="getMinDate(monthDateLimit, maxDate)"
              :disabled="
                (modalName === 'edit' && selects.close) ||
                !canEdit ||
                (modalName === 'edit' && isAutomatic && !canEditAutomaticPlan) ||
                !selects.start ||
                canEditOnlyNameAndCommercials ||
                blockAMAChangeAutomatonPeriod
              "
            />
          </b-form-group>
        </div>

        <div class="d-flex align-items-center justify-content-between mb-3 flex-wrap">
          <b-form-group style="width: 49%" :label="$t('table.selectGroup')">
            <MultiSelect
              v-model="selects.brand"
              :options="brandsList"
              :placeholder="$t('table.selectGroup')"
              label="name"
              track-by="id"
              data-automatic="ms-brand"
              :disabled="modalName === 'edit' || !canEdit || (modalName === 'edit' && isAutomatic && !canEditAutomaticPlan) || canEditOnlyNameAndCommercials"
              @input="loadCommercialsList"
            ></MultiSelect>
          </b-form-group>
          <b-form-group style="width: 49%" :label="$t('table.budget')">
            <currency-input
              v-model="selects.budget"
              :placeholder="$t('table.budget')"
              data-automatic="input-budget"
              :disabled="(modalName === 'edit' && selects.close) || !canEdit || !canEditBudget || canEditOnlyNameAndCommercials"
            />
          </b-form-group>
          <b-form-group style="width: 49%" :label="$t('table.fact') + ' (' + (is_budget_fixed ? $t('table.userFixed') : $t('table.unfixedCalcBySystem')) + ')'">
            <b-input-group>
              <currency-input
                v-model="selects.fact"
                :placeholder="$t('table.fact')"
                required
                data-automatic="input-fact-budget"
                :disabled="
                  !is_budget_fixed ||
                  (modalName === 'edit' && selects.close) ||
                  !canEdit ||
                  (modalName === 'edit' && isAutomatic && !canEditAutomaticPlan) ||
                  canEditOnlyNameAndCommercials
                "
              />
              <b-input-group-append v-if="canEditPrice">
                <div class="custom-control custom-switch input-group-text pl-5" :class="isThemeHeader === 'true' ? 'bg-dark white-color' : 'bg-white'">
                  <input
                    id="customSwitch-fact"
                    v-model="is_budget_fixed"
                    type="checkbox"
                    class="custom-control-input"
                    :disabled="
                      !canEdit ||
                      (modalName === 'edit' && selects.close) ||
                      (modalName === 'edit' && isAutomatic && !canEditAutomaticPlan) ||
                      canEditOnlyNameAndCommercials
                    "
                    @input="is_budget_fixed = !is_budget_fixed"
                  />
                  <label class="custom-control-label" for="customSwitch-fact">{{ $t('table.fixed') }}</label>
                </div>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-checkbox
            v-if="!isAMA"
            id="checkbox-taxes"
            v-model="selects.taxes"
            name="checkbox-taxes"
            class="custom-control-inline ml-auto mt-3"
            :disabled="
              !canEdit ||
              (modalName === 'edit' && isAutomatic && !canEditAutomaticPlan) ||
              !canEditBudget ||
              (modalName === 'edit' && selects.close) ||
              canEditOnlyNameAndCommercials
            "
          >
            {{ $t('table.taxes') }}
          </b-form-checkbox>
          <b-form-checkbox
            v-if="!isAMA"
            id="checkbox-close"
            v-model="selects.close"
            name="checkbox-close"
            class="custom-control-inline ml-1 mt-3"
            :disabled="!canEdit || (modalName === 'edit' && !canEditAutomaticPlan) || canEditOnlyNameAndCommercials"
            @change="changeCloseMPState"
          >
            {{ $t('table.close') }}
          </b-form-checkbox>
          <b-form-checkbox
            v-if="selects.type && selects.type.name === 'Automatic' && canEditAutomaticPlan && !isAMA"
            id="checkbox-deny-pulling"
            v-model="selects.denyPulling"
            name="checkbox-deny-pulling"
            class="custom-control-inline ml-1 mt-3"
            :disabled="!canEdit || (modalName === 'edit' && !canEditAutomaticPlan) || canEditOnlyNameAndCommercials"
          >
            {{ $t('table.denyPulling') }}
          </b-form-checkbox>
          <b-button
            v-if="canViewDiscounts"
            class="mt-3"
            :disabled="(isAutomatic && !canEditAutomaticPlan) || !(selects.channel && selects.start && selects.end)"
            data-automatic="discounts-btn"
            :title="!(selects.channel && selects.start && selects.end) ? $t('table.selectChannelAndDatesFirst') : ''"
            @click="isOpenModalDiscounts = true"
          >
            {{ $t('table.discounts') }}
          </b-button>
        </div>

        <div>
          <b-form-group :label="$t('table.selectComm')">
            <div class="d-flex justify-content-between">
              <select
                v-model="selectAdd"
                size="5"
                class="custom-select"
                :disabled="
                  (modalName === 'edit' && selects.close) || !canEdit || !selects.brand || (modalName === 'edit' && isAutomatic && !canEditAutomaticPlan)
                "
                data-automatic="available-commercials"
              >
                <option v-for="option in options" :key="option.id" :value="option">[{{ option.id }}] {{ option.name }}</option>
              </select>
              <div class="py-4">
                <button
                  class="arrow-btn"
                  type="button"
                  :disabled="
                    (modalName === 'edit' && selects.close) ||
                    !canEdit ||
                    !selects.brand ||
                    (modalName === 'edit' && isAutomatic && !canEditAutomaticPlan) ||
                    !selects.start ||
                    !selectAdd
                  "
                  data-automatic="add-commercial-btn"
                  @click="leftHandler"
                >
                  <b-icon class="add-icon__left" font-scale="4" icon="arrow-right"></b-icon>
                </button>
                <button
                  class="arrow-btn"
                  type="button"
                  :disabled="
                    (modalName === 'edit' && selects.close) ||
                    !canEdit ||
                    !selects.brand ||
                    (modalName === 'edit' && isAutomatic && !canEditAutomaticPlan) ||
                    !selectDelete
                  "
                  data-automatic="remove-commercial-btn"
                  @click="rightHandler"
                >
                  <b-icon class="add-icon__right" font-scale="4" icon="arrow-left"></b-icon>
                </button>
              </div>
              <select
                v-model="selectDelete"
                size="5"
                class="custom-select selected-spots"
                data-automatic="selected-commercials"
                :disabled="
                  (modalName === 'edit' && selects.close) || !canEdit || !selects.brand || (modalName === 'edit' && isAutomatic && !canEditAutomaticPlan)
                "
              >
                <option
                  v-for="(v, index) in value"
                  :key="v.id + '-' + v.double_spot_id + index"
                  :value="v"
                  :class="[v.double_spot_id ? 'ds-option' : '', v.couple ? 'couple' : '']"
                >
                  {{ isSafari && v.double_spot_id ? '(DS) ' : '' }}
                  [{{ v.id }}] {{ v.name }} {{ v.mark ? '(' + v.mark + ')' : '' }} {{ v.co_brand_id != null ? `[CoBrand]` : '' }}
                </option>
              </select>
            </div>

            <div class="d-flex mt-2 justify-content-between">
              <b-button
                v-if="$checkPermissions('commercial.read_list')"
                data-automatic="inspect-commercials-btn"
                :disabled="!selects.brand || (modalName === 'edit' && isAutomatic && !canEditAutomaticPlan)"
                @click="[(isOpenModalInspectCommercial = true), (modalInspectCommercial = 'modal-inspect-commercial')]"
              >
                {{ $t('table.inspect') }}
              </b-button>
              <!-- COMMENTED FOR A BETTER DAY (DoubleSpot btns) -->
              <!-- <div class="d-flex gap-3">
                <b-button

                  data-automatic="add-ds-btn"
                  :disabled="(modalName === 'edit' && selects.close) || !canEdit || (modalName === 'edit' && isAutomatic && !canEditAutomaticPlan)"
                  @click="showModalDoubleSpot('add')"
                >
                  {{ $t('table.addDoubleSpot') }}
                </b-button>
                <b-button

                  data-automatic="edit-ds-btn"
                  :disabled="
                    !(selectDelete && selectDelete.double_spot_id) ||
                    (modalName === 'edit' && selects.close) ||
                    !canEdit ||
                    (modalName === 'edit' && isAutomatic && !canEditAutomaticPlan)
                  "
                  @click="showModalDoubleSpot('edit', selectDelete.double_spot_id)"
                >
                  {{ $t('table.editDoubleSpot') }}
                </b-button>
              </div> -->
            </div>
          </b-form-group>
        </div>

        <h5>{{ $t('table.wGRPsPlan') }}</h5>
        <table id="automatonTableSettings" class="table table-sm small">
          <thead>
            <th>{{ $t('table.commercial') }}/{{ $t('table.date') }}</th>
            <th>% {{ $t('table.deviation') }}</th>
            <th>wGRP's</th>
            <th>% {{ $t('channelModal.prime') }}</th>
            <th>{{ $t('table.period') }}</th>
          </thead>
          <tbody>
            <template v-for="(comm, index) in commercialsSettings">
              <tr :key="index">
                <td colspan="5">
                  <button
                    v-if="canEdit && (canEditWGRPPlan || allowAMAEditMPCWithFixedGrpType)"
                    class="btn btn-outline-danger btn-sm mr-3"
                    data-automatic="add-wgrp-row-btn"
                    :disabled="modalName === 'edit' && selects.close"
                    @click.prevent="addPeriod(index, comm)"
                  >
                    {{ $t('table.addPeriod') }}
                  </button>
                  <label class="font-weight-bold mb-0" :class="comm.double_spot_id ? 'ds-option' : ''">
                    {{
                      comm.double_spot_id
                        ? comm.name +
                          ' - ' +
                          (value.find((el) => el.double_spot_id === comm.double_spot_id && el.id !== comm.id)
                            ? value.find((el) => el.double_spot_id === comm.double_spot_id && el.id !== comm.id).name
                            : '')
                        : `[${comm.id}] ${comm.name}`
                    }}
                    ({{ comm.duration }} {{ $t('table.sec') }})
                    <CoBrandBadge v-if="comm.co_brand_id != null" />
                  </label>
                </td>
              </tr>
              <template v-for="(settings, index2) in comm.settings">
                <tr :key="index + '-' + index2 + '_1'">
                  <td>
                    <div class="d-flex gap-2">
                      <datepicker-wrapper
                        v-model="settings.date_start_at"
                        style="width: 200px; height: calc(1.5em + 0.5rem + 2px)"
                        data-automatic="dp-wgrp-date-from"
                        required
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        :min="getMaxDate(selects.start, minDate)"
                        :max="getMinDate(comm.legal_before_at, selects.end, settings.date_end_at, monthDateLimit, maxDate)"
                        size="sm"
                        :disabled="(modalName === 'edit' && selects.close) || !canEdit || !(canEditWGRPPlan || allowAMAEditMPCWithFixedGrpType)"
                      />
                      <datepicker-wrapper
                        v-model="settings.date_end_at"
                        style="width: 200px; height: calc(1.5em + 0.5rem + 2px)"
                        data-automatic="dp-wgrp-date-to"
                        required
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        :min="getMaxDate(settings.date_start_at, selects.start, minDate)"
                        :max="getMinDate(comm.legal_before_at, selects.end, monthDateLimit, maxDate)"
                        size="sm"
                        :disabled="(modalName === 'edit' && selects.close) || !canEdit || !(canEditWGRPPlan || allowAMAEditMPCWithFixedGrpType)"
                      />
                    </div>
                  </td>
                  <td>
                    <b-form-input
                      v-model="settings.deviation"
                      type="number"
                      :placeholder="$t('table.deviation')"
                      required
                      step="any"
                      min="0"
                      max="100"
                      size="sm"
                      data-automatic="input-wgrp-deviation"
                      :disabled="(modalName === 'edit' && selects.close) || !canEdit || !(canEditWGRPPlan || allowAMAEditMPCWithFixedGrpType)"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      v-model="settings.grp"
                      type="number"
                      placeholder="wGRP"
                      required
                      step="any"
                      min="-10000"
                      max="100000"
                      size="sm"
                      data-automatic="input-wgrp-grp"
                      :disabled="(modalName === 'edit' && selects.close) || !canEdit || !(canEditWGRPPlan || allowAMAEditMPCWithFixedGrpType)"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      v-model="settings.prime_percent"
                      type="number"
                      placeholder="Prime %"
                      required
                      step="any"
                      min="0"
                      max="100"
                      size="sm"
                      data-automatic="input-wgrp-prime-percent"
                      :disabled="(modalName === 'edit' && selects.close) || !canEdit || !(canEditWGRPPlan || allowAMAEditMPCWithFixedGrpType)"
                    ></b-form-input>
                  </td>
                  <td>
                    <div class="d-flex gap-2">
                      <BroadcastTimePicker
                        :id="`timepicker-input-start-${index}-${index2}`"
                        v-model="settings.start_interval"
                        size="sm"
                        required
                        :interval-mode="true"
                        :disabled="(modalName === 'edit' && selects.close) || !canEdit || !(canEditWGRPPlan || allowAMAEditMPCWithFixedGrpType)"
                      />

                      <BroadcastTimePicker
                        :id="`timepicker-input-end-${index}-${index2}`"
                        v-model="settings.end_interval"
                        size="sm"
                        required
                        :interval-mode="true"
                        :disabled="(modalName === 'edit' && selects.close) || !canEdit || !(canEditWGRPPlan || allowAMAEditMPCWithFixedGrpType)"
                      />

                      <b-icon
                        v-if="
                          (modalName === 'edit' && !selects.close && canEdit && (canEditWGRPPlan || allowAMAEditMPCWithFixedGrpType)) ||
                          (modalName === 'add' && canEdit && (canEditWGRPPlan || allowAMAEditMPCWithFixedGrpType))
                        "
                        class="trash-hover cursor-pointer mt-2 ml-2"
                        icon="trash"
                        :title="$t('table.delete')"
                        @click="removeRow(index, index2)"
                      ></b-icon>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td class="text-right"><label class="font-weight-bold mb-0">Total</label></td>
              <td></td>
              <td>
                {{ commercialSettingsWgrpTotal }}
              </td>
              <td></td>
              <td></td>
            </tr>
          </tfoot>
        </table>

        <!-- AUTOMATON SECTION -->
        <template v-if="isAutomatic">
          <h5>{{ $t('table.automatonSettings') }}</h5>
          <b-form-group :label="$t('table.exceptedPrograms')">
            <MultiSelect
              v-model="exceptedPrograms"
              :options="programs ? programs.data : []"
              :multiple="true"
              :close-on-select="false"
              :placeholder="$t('table.exceptedPrograms')"
              label="name"
              track-by="id"
              :disabled="(modalName === 'edit' && selects.close) || !canEdit || !canEditAutomaticPlan || canEditOnlyNameAndCommercials || isAMA"
              data-automatic="ms-excepted-programs"
            ></MultiSelect>
          </b-form-group>
          <BButtonEnh
            v-if="modalName === 'edit' && canRunAutomaton"
            v-b-modal.modal-automatic
            data-automatic="run-automaton"
            class="mr-2"
            :disabled="!isAutomatonCompleted || selects.close"
            :busy-state="!isAutomatonCompleted"
            :title="selects.close ? $t('table.mpIsClosed') : ''"
          >
            {{ $t('table.runAutomaton') }}
          </BButtonEnh>

          <ModalAutomatic
            :min-date="minDate"
            :max-date="maxDate"
            :start-date="selects.start"
            :end-date="selects.end"
            storage-key="MediaPlansRunSettings"
            @run-automaton="runAutomaton"
          />

          <div v-if="isAutomatonCompleted && automatonResult" class="mt-3">
            <h4>{{ $t('table.resultOfAutomaton') }}</h4>
            <div class="table-responsive">
              <table class="table w-100 table-sm small">
                <thead>
                  <tr>
                    <th>{{ $t('table.name') }}</th>
                    <th>{{ $t('table.period') }}</th>
                    <th class="text-right">{{ $t('table.deviation') }} %</th>
                    <th class="text-right">
                      <abbr :title="`${$t('table.fact')} (${$t('table.plan')})`">{{ $t('booking.total') }}</abbr>
                    </th>
                    <th class="text-right">
                      <abbr :title="`${$t('table.fact')} (${$t('table.plan')})`">{{ $t('channelModal.prime') }}</abbr>
                    </th>
                    <th class="text-right">
                      <abbr :title="`${$t('table.fact')} (${$t('table.plan')})`">{{ $t('channelModal.offPrime') }}</abbr>
                    </th>
                    <th class="text-right">{{ $t('table.spotsGenitive') }} {{ $t('channelModal.prime') }}</th>
                    <th class="text-right">{{ $t('table.spotsGenitive') }} {{ $t('channelModal.offPrime') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(comm, name) in automatonResult.data">
                    <tr :key="name" :class="isThemeHeader === 'true' ? 'bg-dark-lighter' : 'bg-light'">
                      <th colspan="3">[{{ comm.data[0].commercial_id }}] {{ name }}</th>
                      <th class="text-right">
                        {{ comm.totals.wgrp | toFixedAndSpace }} ({{ comm.totals.grp | toFixedAndSpace }})<br />
                        {{ comm.totals.total_price | toFixedAndSpace }}
                      </th>
                      <th class="text-right">
                        {{ comm.totals.prime_wgrp | toFixedAndSpace }} ({{ comm.totals.prime_grp | toFixedAndSpace }})<br />
                        {{ comm.totals.prime_price | toFixedAndSpace }}
                      </th>
                      <th class="text-right">
                        {{ comm.totals.off_prime_wgrp | toFixedAndSpace }} ({{ comm.totals.off_prime_grp | toFixedAndSpace }})<br />
                        {{ comm.totals.off_prime_price | toFixedAndSpace }}
                      </th>
                      <th class="text-right">
                        {{ comm.totals.spot_count_prime }}
                      </th>
                      <th class="text-right">
                        {{ comm.totals.spot_count_off_prime }}
                      </th>
                    </tr>
                    <tr v-for="asc in comm.data" :key="name + '_' + asc.acs_id">
                      <td>
                        wGRP Fact (Target)
                        <br />
                        {{ $t('table.price') }}
                      </td>
                      <td>
                        {{ asc.date_start_at | convertDate }}-{{ asc.date_end_at | convertDate }}<br />
                        {{ sec2time(asc.start_interval) }}-{{ sec2time(asc.end_interval) }}
                      </td>
                      <td class="text-right">{{ asc.deviation }}</td>
                      <td class="text-right">
                        {{ asc.wgrp | toFixedAndSpace }} ({{ asc.grp | toFixedAndSpace }})<br />
                        {{ asc.total_price | toFixedAndSpace }}
                      </td>
                      <td class="text-right">
                        {{ asc.prime_wgrp | toFixedAndSpace }} ({{ asc.prime_grp | toFixedAndSpace }})<br />
                        {{ asc.prime_price | toFixedAndSpace }}
                      </td>
                      <td class="text-right">
                        {{ asc.off_prime_wgrp | toFixedAndSpace }} ({{ asc.off_prime_grp | toFixedAndSpace }})<br />
                        {{ asc.off_prime_price | toFixedAndSpace }}
                      </td>
                      <td class="text-right">
                        {{ asc.spot_count_prime }}
                      </td>
                      <td class="text-right">
                        {{ asc.spot_count_off_prime }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tfoot>
                  <tr>
                    <th colspan="3">{{ $t('booking.total') }}</th>
                    <th class="text-right">
                      {{ automatonResult.totals.wgrp | toFixedAndSpace }} ({{ automatonResult.totals.grp | toFixedAndSpace }})<br />
                      {{ automatonResult.totals.total_price | toFixedAndSpace }}
                    </th>
                    <th class="text-right">
                      {{ automatonResult.totals.prime_wgrp | toFixedAndSpace }} ({{ automatonResult.totals.prime_grp | toFixedAndSpace }})<br />
                      {{ automatonResult.totals.prime_price | toFixedAndSpace }}
                    </th>
                    <th class="text-right">
                      {{ automatonResult.totals.off_prime_wgrp | toFixedAndSpace }} ({{ automatonResult.totals.off_prime_grp | toFixedAndSpace }})<br />
                      {{ automatonResult.totals.off_prime_price | toFixedAndSpace }}
                    </th>
                    <th class="text-right">
                      {{ automatonResult.totals.spot_count_prime }}
                    </th>
                    <th class="text-right">
                      {{ automatonResult.totals.spot_count_off_prime }}
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </template>
      </form>
    </b-modal>

    <!-- CONFIRM -->
    <b-modal
      ref="modal-media-plan-confirm"
      size="sm"
      :title="modalName === 'add' ? $t('table.addMP') : $t('table.editMP')"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      auto-focus-button="ok"
      :busy="isModalBusy"
      @ok.prevent="MediaPlan()"
    >
      {{ modalName === 'add' ? $t('table.addMP') : $t('table.editMP') }} {{ selects.mediaPlan }}?
    </b-modal>

    <!-- DELETE MEDIA PLAN MODAL -->
    <b-modal
      ref="delete-media-plan-confirm"
      size="sm"
      :title="$t('table.deleteMediaPlan')"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      auto-focus-button="ok"
      :busy="isModalBusy"
      @ok.prevent="deleteMediaPlans"
    >
      <p class="my-2">
        {{ $t('table.deleteMediaPlan') }}
        <span class="text-danger">{{ modalCurrentMediaPlan ? modalCurrentMediaPlan.name : '' }}</span>
        ?
      </p>
    </b-modal>

    <ModalDiscounts
      modal-name="mediaPlan"
      :discounts-edit-list="discountsEditList"
      :premium-discounts-edit-list="premiumDiscountsEditList"
      :modal-discounts-type="modalName"
      :is-open-modal="isOpenModalDiscounts"
      :can-edit="(modalName === 'edit' && selects.close) || canEditOnlyNameAndCommercials ? false : canEditDiscounts"
      :can-delete="(modalName === 'edit' && selects.close) || canEditOnlyNameAndCommercials ? false : canDeleteDiscounts"
      :can-add="(modalName === 'edit' && selects.close) || canEditOnlyNameAndCommercials ? false : canAddDiscounts"
      :channels_id_list="selects.channel ? [selects.channel.id] : undefined"
      :date_from="selects.start ? selects.start : undefined"
      :date_to="selects.end ? selects.end : undefined"
      :project-discounts="projectDiscounts"
      :project-id="projectId"
      :order-discounts="orderDiscounts"
      :co-branding-discount.sync="co_branding_discount"
      @isOpenModal="isOpenModalDiscounts = false"
      @transferDiscounts="transferDiscounts"
      @refreshData="refreshData"
      @removeDiscount="removeDiscount"
    />

    <ModalInspectCommercial
      :modal-discounts-type="modalInspectCommercial"
      :is-open-modal="isOpenModalInspectCommercial"
      :project-advertiser-id="projectAdvertiserId"
      :brand-id="selects.brand ? selects.brand.id : undefined"
      :commercial-type-id="commercialTypeId"
      :agency-id="agencyId"
      @isOpenModal="isOpenModalInspectCommercial = false"
      @updateCommercials="loadCommercialsListAPI"
    />

    <ModalDoubleSpot :type="modalDsType" :commercials="commercialsForModalDS" @doubleSpot="syncDoubleSpots" />
  </div>
</template>

<script>
import MultiSelect from '@/components/MultiSelect';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import errorsHandler from '@/utils/errorsHandler';
import ModalDiscounts from '@/components/Projects/ModalDiscounts';
import { mapGetters } from 'vuex';
import ModalInspectCommercial from './ModalInspectCommercial';
import ModalDoubleSpot from './ModalDoubleSpot';
import SpinnerLoader from '@/components/SpinnerLoader';
import CurrencyInput from '@/components/CurrencyInput';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import toFixedAndSpace from '@/filters/toFixedAndSpace';
import convertDate from '@/filters/convertDate';
import getMinOrMaxDate from '@/mixins/getMinOrMaxDate';
import BroadcastTimePicker from '@/components/BroadcastTimePicker';
import timeConvertHelpers from '@/mixins/timeConvertHelpers';
import { getCommercials } from '../../api/apiServices';
import ModalAutomatic from '../MediaPlansSummary/ModalAutomatic.vue';

export default {
  name: 'ModalMediaPlan',
  components: {
    ModalInspectCommercial,
    MultiSelect,
    ModalDiscounts,
    ModalDoubleSpot,
    SpinnerLoader,
    CurrencyInput,
    DatepickerWrapper,
    BButtonEnh: () => import('@/components/BButtonEnh.vue'),
    BroadcastTimePicker,
    CoBrandBadge: () => import('@/components/CoBrandBadge.vue'),
    ModalAutomatic,
  },
  filters: {
    convertDate,
    toFixedAndSpace,
  },
  mixins: [getMinOrMaxDate, timeConvertHelpers],
  props: {
    isOpenModal: { type: String, default: undefined },
    modalName: { type: String, default: 'add' },
    orderSettings: { required: true, type: Array },
    modalCurrentMediaPlan: { type: Object, default: undefined },
    commercialTypeId: { required: true, type: Number },
    placementTypeId: { required: true, type: Number },
    agencyId: { required: true, type: Number },
    projectId: { required: true, type: Number },
    projectName: { required: true, type: String },
    orderName: { required: true, type: String },
    minDate: { required: true, type: String },
    maxDate: { required: true, type: String },
    canEdit: { type: Boolean },
    projectAdvertiserId: { required: true, type: Number },
    projectDiscounts: { required: true, type: Array },
    orderDiscounts: { required: true, type: Array },
    allowAMAEditMPC: { type: Boolean },
  },
  data() {
    return {
      value: [],
      options: [],
      modalInspectCommercial: null,
      isOpenModalDiscounts: false,
      isOpenModalInspectCommercial: false,
      selectAdd: '',
      selectDelete: '',
      orderChannelsList: [],
      selects: {
        agency: '',
        mediaPlan: '',
        brand: '',
        taxes: false,
        close: false,
        oneCommercialInBlock: false,
        budget: '',
        start: '',
        end: '',
        commercial: '',
        channel: '',
        measurement: '',
        target: '',
        type: '',
        discounts: [],
        premiumPositions: [],
        denyPulling: false,
        fact: 0,
      },
      discountsEditList: [],
      premiumDiscountsEditList: {},
      modalDsType: 'add',
      commercialsForModalDS: [],
      isAutomatic: false,
      commercialsSettings: [],
      uniqueCommercialsList: [],
      exceptedPrograms: [],
      automatonResult: null,
      isAutomatonCompleted: false,
      isLoadingModal: false,
      is_budget_fixed: false,
      isModalBusy: false,

      commercials: null,

      co_branding_discount: null,
    };
  },
  validations() {
    return {
      selects: {
        mediaPlan: { required },
        brand: { required },
        // budget: {required},
        start: { required },
        end: { required },
        channel: { required },
        target: { required },
        type: { required },
        commercial: { required },
      },
      commercialsSettings: {
        //required,
        $each: {
          settings: {
            //required,
            $each: {
              date_start_at: { required },
              date_end_at: { required },
              deviation: { required, minValue: minValue(0), maxValue: maxValue(100) },
              grp: { required, minValue: minValue(-10000), maxValue: maxValue(100000) },
              prime_percent: { required, minValue: minValue(0), maxValue: maxValue(100) },
              start_interval: { required },
              end_interval: { required },
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      commercialTypeWithoutAll: 'getCommercialTypeWithoutAll',
      channelsList: 'getChannelsList',
      measurementsList: 'getMeasurementsList',
      targetList: 'getTargetList',
      placementTypeWithoutAll: 'getPlacementTypeWithoutAll',
      agenciesList: 'getAgenciesList',
      modalEditMediaPlans: 'getModalEditMediaPlans',
      programs: 'getPrograms',
      brandsList: 'getBrandsList',
      isDev: 'getDevFlag',
      BROADCAST_START_TIME_IN_SEC: 'getBroadcastStartInterval',
      isAMA: 'getIsCurrentUserAgencyManager',
      isAdmin: 'getIsCurrentUserAdmin',
      modalEditProjects: 'getModalEditProjects',
    }),
    isSafari: () => navigator && navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1,
    canViewDiscounts() {
      return this.$checkPermissions('scope.pom_discount');
    },
    canAddDiscounts() {
      return this.$checkPermissions('discount.pom_update');
    },
    canEditDiscounts() {
      return this.$checkPermissions('discount.pom_update');
    },
    canDeleteDiscounts() {
      return this.$checkPermissions('discount.pom_update');
    },
    canEditBudget() {
      return this.$checkPermissions('price.pom_update');
    },
    canEditWGRPPlan() {
      return this.$checkPermissions('mediaplan.wgrp_update');
    },
    canEditAutomaticPlan() {
      return this.$checkPermissions('mediaplan.automatic_update');
    },
    canEditPrice() {
      return this.$checkPermissions('price.pom_update');
    },
    isCrossChannel() {
      return this.selects.type?.name === 'Cross-channel';
    },
    monthDateLimit() {
      return this.selects.start
        ? new Date(Date.UTC(+this.selects.start.slice(0, 4), +this.selects.start.slice(5, 7), 0)).toISOString().slice(0, 10)
        : undefined;
    },
    canEditOnlyNameAndCommercials() {
      return this.$checkPermissions('mediaplan.update_only_name_and_commercials') && this.modalName === 'edit';
    },
    commercialSettingsWgrpTotal() {
      return this.commercialsSettings.reduce((acc, current) => current.settings.reduce((acc2, current2) => acc2 + +current2.grp, acc), 0).toFixed(4);
    },
    canReadProject() {
      return this.$checkPermissions('project.read');
    },
    allowAMAEditMPCWithFixedGrpType() {
      return this.allowAMAEditMPC && this.selects.type?.name === 'GRP fixed';
    },
    blockAMAChangeMPType() {
      return this.isAMA && this.modalName === 'edit';
    },
    blockAMAChangeAutomatonPeriod() {
      return this.isAMA && this.modalName === 'edit' && this.selects.type?.name === 'Automatic';
    },
    canRunAutomaton() {
      return this.$checkPermissions('scope.automatic_start');
    },
  },
  watch: {
    async isOpenModal() {
      await this.showModalMediaPlan();
    },

    commercials() {
      if (this.commercials && this.commercials.data && !this.isLoadingModal) {
        this.options = this.commercials.data.filter((el) => el.is_visible); // Show only visible commercials
        // Reload left select options after comm update
        if (this.value?.length > 0) {
          const ids = this.value.map((el) => el.id);
          this.options = this.options.filter((o) => !ids.includes(o.id));
          //update selected commercials fields
          this.value.forEach((v) => {
            const parentCommercial = this.commercials.data.find((c) => c.id === v.id);
            if (!parentCommercial) return;
            v.name = parentCommercial.name;
            v.duration = parentCommercial.duration;
            v.legal_before_at = parentCommercial.legal_before_at;
          });
          this.uniqueCommercialsList = [];
          this.uniqueCommercialsList = [...new Map(this.value.map((item) => [(item['id'] && item['double_spot_id']) || item['id'], item])).values()];
          this.uniqueCommercialsList.forEach((comm) => {
            const valueCommercial = this.commercialsSettings.find((el) => el.id === comm.id && el.double_spot_id === comm.double_spot_id);
            valueCommercial.name = comm.name;
            valueCommercial.duration = comm.duration;
            valueCommercial.legal_before_at = comm.legal_before_at;
            valueCommercial.co_brand_id = comm.co_brand_id;
          });
        }
      }
    },

    selectDelete() {
      if (this.selectDelete) {
        this.value.forEach((el) => {
          if (el.double_spot_id) {
            if (el.double_spot_id === this.selectDelete.double_spot_id) {
              el.couple = 'couple';
            } else {
              delete el.couple;
            }
          }
        });
      }
    },

    'selects.start'(newValue) {
      if (!this.selects.end || !newValue) return;
      const endOfMonth = new Date(Date.UTC(+newValue.slice(0, 4), +newValue.slice(5, 7), 0)).toISOString().slice(0, 10);
      // check for month limit for end date || check start =< end
      if (new Date(endOfMonth).getTime() < new Date(this.selects.end).getTime() || new Date(newValue).getTime() > new Date(this.selects.end).getTime())
        this.selects.end = endOfMonth;
    },

    async 'selects.channel'(value) {
      //autofill cobrand surcharge from Project PP if possible
      if (value && this.canReadProject && !this.isLoadingModal && this.modalName === 'add') {
        if (!(this.modalEditProjects && this.modalEditProjects.id === this.projectId))
          await this.$store.dispatch('GET_PROJECTS_ID', {
            id: this.projectId,
            data: {
              include: 'channelProjects.channelProjectPriceProject',
            },
          });
        const channel = this.modalEditProjects.channel_projects.find((c) => c.channel_id === value.id);
        if (channel?.channel_project_price_project) this.co_branding_discount = channel.channel_project_price_project.co_branding_discount;
      }
    },
  },
  methods: {
    getTitle() {
      if (this.modalName === 'add') return this.$i18n.t('table.addMP');
      else if (this.modalName === 'edit' && !this.canEdit) return this.selects.mediaPlan;
      else return this.$i18n.t('table.editMP') + ' (ID: ' + this.modalCurrentMediaPlan?.id + ')';
    },
    leftHandler() {
      if (this.selectAdd && this.selects.start) {
        if (this.selectAdd.legal_before_at && new Date(this.selectAdd.legal_before_at).getTime() < new Date(this.selects.start).getTime()) {
          //show modal when commercial legal_before_at < MP start date
          this.$bvModal.msgBoxOk(this.$i18n.t('table.cantAddCommercialInMP', [convertDate(this.selectAdd.legal_before_at)]));
          return;
        }
        this.options = this.options.filter((option) => option.id !== this.selectAdd.id);
        this.value.push(this.selectAdd);
        this.selectAdd = '';
        this.updateCommercialsListAndSettings();
      }
    },
    rightHandler() {
      if (this.selectDelete) {
        if (this.selectDelete.double_spot_id) {
          // Double Spot delete
          const dsId = this.selectDelete.double_spot_id;
          const pair = this.value.filter((el) => el.double_spot_id === dsId);
          this.value = this.value.filter((option) => option.double_spot_id !== dsId);
          pair.forEach((el) => {
            delete el.double_spot_id;
            delete el.distance;
            delete el.couple;
            // Push to the left if no duplicate
            if (this.options.some((opt) => opt.id !== el.id) || this.options.length === 0) {
              this.options.push(el);
            }
          });

          this.selectDelete = '';
        } else {
          // Remove single commercial
          this.value = this.value.filter((option) => option.id !== this.selectDelete.id);
          this.options.push(this.selectDelete);
          this.selectDelete = '';
        }
        this.updateCommercialsListAndSettings();
      }
    },
    async showModalMediaPlan() {
      if (this.isOpenModal) {
        if (this.modalName !== 'delete') {
          this.isLoadingModal = true;
          this.$refs['modal-media-plan'].show();
          this.clearFields();
          await Promise.all([
            !this.channelsList || (this.channelsList && this.channelsList.length < 1) ? this.$store.dispatch('GET_CHANNELS', { per_page: 1000 }) : undefined,
            this.measurementsList.length < 1 ? this.$store.dispatch('GET_MEASUREMENTS', { per_page: 1000 }) : undefined,
            this.placementTypeWithoutAll.length < 1 ? this.$store.dispatch('GET_PLACEMENT_TYPE', { per_page: 1000 }) : undefined,
          ]);
          this.selects.agency = this.agenciesList.find((ag) => ag.id === this.agencyId);
          // Load unique channels list from parent Order
          let uniqueChannels = [...new Set(this.orderSettings.map((item) => item.channel_id))];
          uniqueChannels.forEach((el) => {
            const channel = this.channelsList.find((channel) => channel.id === el);
            if (channel) this.orderChannelsList.push(channel);
          });
          if (this.modalName === 'edit') {
            await this.$store.dispatch('GET_MEDIA_PLANS_ID', {
              id: this.modalCurrentMediaPlan.id,
              data: { include: 'commercials,discounts,automaticMediaplanPrograms,premiumPositionDiscounts' },
            });
            if (this.modalEditMediaPlans) {
              this.selects.mediaPlan = this.modalEditMediaPlans.name;
              this.selects.brand = this.brandsList.find((brand) => brand.id === this.modalCurrentMediaPlan.brand_id);
              this.selects.channel = this.orderChannelsList.find((channel) => channel.id === this.modalCurrentMediaPlan.channel_id);
              await this.$store.dispatch('GET_TARGET', { per_page: 1000 });
              this.selects.target = this.targetList.find((el) => el.id === this.modalEditMediaPlans.target_audience_id);
              // get measurement by finding its id in target object
              if (this.selects.target) this.selects.measurement = this.measurementsList.find((el) => el.id === this.selects.target.measurement_company_id);
              this.selects.taxes = this.modalEditMediaPlans.taxes;
              this.selects.close = this.modalEditMediaPlans.is_closed;
              this.selects.oneCommercialInBlock = this.modalEditMediaPlans.is_one_commercial_in_block;
              this.selects.budget = +this.modalEditMediaPlans.estimated_budget;
              this.selects.fact = +this.modalEditMediaPlans.fact_budget || 0;
              this.is_budget_fixed = this.modalEditMediaPlans.is_budget_fixed;
              this.selects.start = this.modalEditMediaPlans.date_from;
              this.selects.end = this.modalEditMediaPlans.date_to;
              this.selects.commercial = this.commercialTypeWithoutAll.find((type) => type.id === this.modalEditMediaPlans.commercial_type_id);
              this.selects.type = this.placementTypeWithoutAll.find((type) => type.id === this.modalEditMediaPlans.placement_type_id);
              this.isAutomatonCompleted = true;
              await this.loadCommercialsList();
              this.calculateAutomatonResultTotals(this.modalEditMediaPlans?.automatic_info);
              this.value = this.modalEditMediaPlans.commercials;
              const ids = this.value.map((el) => el.id);
              this.options = this.options.filter((o) => !ids.includes(o.id));
              this.discountsEditList = this.modalEditMediaPlans.discounts;
              this.premiumDiscountsEditList = this.modalEditMediaPlans.premium_position_discounts;
              this.selects.premiumPositions = this.modalEditMediaPlans.premium_position_discounts;
              this.co_branding_discount = this.modalEditMediaPlans.co_branding_discount;

              if (this.selects.type?.name === 'Automatic') {
                this.isAutomatic = true;
                this.selects.denyPulling = this.modalEditMediaPlans.disallow_ejection;
                if (!(this.programs && this.programs.data.length > 0)) await this.$store.dispatch('GET_PROGRAMS', { per_page: 1000 });
                this.modalEditMediaPlans.excepted_programs.forEach((ep) =>
                  this.exceptedPrograms.push(this.programs.data.find((el) => ep.program_id === el.id))
                );
              }

              // Form unique list of commercials from edit api
              this.uniqueCommercialsList = [
                ...new Map(this.modalEditMediaPlans.commercials.map((item) => [(item['id'] && item['double_spot_id']) || item['id'], item])).values(),
              ];
              this.uniqueCommercialsList.forEach((comm) => {
                const commSettings = comm?.settings || [];
                if (commSettings) {
                  commSettings.forEach((el) => {
                    delete el.duration;
                  });
                }
                //Push commercial into settings
                this.commercialsSettings.push({
                  id: comm.id,
                  name: comm.name,
                  duration: comm.duration,
                  legal_before_at: comm.legal_before_at,
                  double_spot_id: comm.double_spot_id,
                  distance: comm.distance,
                  co_brand_id: comm.co_brand_id,
                  settings: commSettings,
                });
              });
            }
          } else {
            // MP ADD TYPE
            this.selects.commercial = this.commercialTypeWithoutAll.find((type) => type.id === this.commercialTypeId);
            this.selects.type = this.placementTypeWithoutAll.find((type) => type.id === this.placementTypeId);
            this.selects.start = this.minDate;
            // date_to in range of month
            if (this.selects.start)
              this.selects.end = new Date(Date.UTC(+this.selects.start.slice(0, 4), +this.selects.start.slice(5, 7), 0)).toISOString().slice(0, 10);
          }
          this.isLoadingModal = false;
        } else {
          this.showModalDeleteMediaPlans();
        }
        this.$emit('isOpenModal', null);
      }
    },

    async selectedChannel() {
      if (this.selects.channel?.id) {
        const currObj = this.orderSettings.find((el) => el.channel_id === this.selects.channel.id);
        if (!this.isLoadingModal) {
          await this.$store.dispatch('GET_TARGET', { per_page: 1000 });
          this.selects.target = this.targetList.find((el) => el.id === currObj.target_audience_id);
          // get measurement by finding its id in target object
          this.selects.measurement = this.measurementsList.find((el) => el.id === this.selects.target.measurement_company_id);
        }
      }
    },

    async selectedMeasurement() {
      if (this.selects.measurement) {
        await this.$store.dispatch('GET_TARGET', {
          'filter[measurement_company_id]': this.selects.measurement.id,
        });
        if (this.selects.target?.measurement_company_id !== this.selects.measurement.id) this.selects.target = '';
      } else {
        this.selects.target = '';
      }
    },

    async selectedType() {
      if (this.selects.type?.name === 'Automatic') {
        // Toggle for Automatic type
        this.isAutomatic = true;
        if (!(this.programs && this.programs.data.length > 0)) await this.$store.dispatch('GET_PROGRAMS', { per_page: 1000 });
      } else {
        this.isAutomatic = false;
      }
    },

    updateCommercialsListAndSettings() {
      this.uniqueCommercialsList = [];
      this.uniqueCommercialsList = [...new Map(this.value.map((item) => [(item['id'] && item['double_spot_id']) || item['id'], item])).values()];
      //if there is a new commercial - add settings for it
      this.uniqueCommercialsList.forEach((comm) => {
        if (!this.commercialsSettings.find((el) => el.id === comm.id && el.double_spot_id === comm.double_spot_id)) {
          this.commercialsSettings.push({
            id: comm.id,
            name: comm.name,
            duration: comm.duration,
            legal_before_at: comm.legal_before_at,
            double_spot_id: comm.double_spot_id,
            distance: comm.distance,
            co_brand_id: comm.co_brand_id,
            settings: [],
          });
        }
      });
      //check if commercials settings doesn't include extra commercials
      this.commercialsSettings.forEach((comm, index) => {
        if (!this.uniqueCommercialsList.find((el) => el.id === comm.id && el.double_spot_id === comm.double_spot_id)) {
          this.commercialsSettings.splice(index, 1);
        }
      });
    },

    hideModalMediaPlan() {
      this.$refs['modal-media-plan'].hide();
    },
    showModalMediaPlanConfirm() {
      this.$refs['modal-media-plan-confirm'].show();
    },
    hideModalMediaPlanConfirm() {
      this.$refs['modal-media-plan-confirm'].hide();
    },
    showModalDeleteMediaPlans() {
      this.$refs['delete-media-plan-confirm'].show();
    },
    hideModalDeleteMediaPlansConfirm() {
      this.$refs['delete-media-plan-confirm'].hide();
    },

    transferDiscounts(data) {
      this.selects.discounts = data[0];

      this.discountsEditList = this.discountsEditList.filter((el) => el.id);
      this.discountsEditList.push(...data[0]);

      this.selects.premiumPositions = data[1];
      this.premiumDiscountsEditList = data[1];
    },

    refreshData() {
      this.$emit('refreshMediaPlans', 'restore');
    },

    clearFields() {
      this.orderChannelsList = [];
      this.selects.agency = '';
      this.selects.brand = '';
      this.selects.channel = '';
      this.selects.taxes = false;
      this.selects.close = false;
      this.selects.oneCommercialInBlock = false;
      this.selects.budget = '';
      this.selects.mediaPlan = '';
      this.selects.start = '';
      this.selects.end = '';
      this.selects.commercial = '';
      this.selects.type = '';
      this.selects.target = '';
      this.selects.measurement = '';
      this.selects.discounts = [];
      this.selects.premiumPositions = [];
      this.selects.fact = 0;
      this.selects.denyPulling = false;
      this.value = [];
      this.options = [];
      this.discountsEditList = [];
      this.premiumDiscountsEditList = {};
      this.isAutomatic = false;
      this.commercialsSettings = [];
      this.uniqueCommercialsList = [];
      this.exceptedPrograms = [];
      this.automatonResult = null;
      this.isAutomatonCompleted = false;
      this.is_budget_fixed = false;
      this.co_branding_discount = null;
    },

    async MediaPlan(dontClearFields, revertCloseState) {
      this.isModalBusy = true;
      let commercialsArray = [];
      let discountsList = [];
      this.value.forEach((el) => {
        if (el.double_spot_id) {
          const commSettings = JSON.parse(JSON.stringify(this.commercialsSettings.find((aut) => el.double_spot_id === aut.double_spot_id)?.settings || []));
          commSettings.forEach((commSett) => {
            commSett.duration = commSett.end_interval - commSett.start_interval;
            delete commSett.end_interval;
          });
          commercialsArray.push({
            commercial_id: +el.id,
            double_spot_id: el.double_spot_id,
            distance: +el.distance,
            options: commSettings,
          });
        } else {
          const commSettings = JSON.parse(JSON.stringify(this.commercialsSettings.find((aut) => aut.id === el.id)?.settings || []));
          commSettings.forEach((commSett) => {
            commSett.duration = commSett.end_interval - commSett.start_interval;
            delete commSett.end_interval;
          });
          commercialsArray.push({
            commercial_id: +el.id,
            options: commSettings,
          });
        }
      });
      this.selects.discounts.forEach((el) => {
        if (!el.id && el.fromType === 'Media Plan') {
          discountsList.push({
            discount_type_id: +el.discount_type_id,
            from: el.from,
            to: el.to,
            percent: +el.percent,
          });
        }
      });
      const excepted_programs = this.exceptedPrograms.map((el) => el.id);
      const formData = {
        order_id: +this.modalCurrentMediaPlan.order_id,
        brand_id: +this.selects.brand?.id,
        is_automatic: this.isAutomatic,
        excepted_programs: excepted_programs,
        channel_id: +this.selects.channel.id,
        placement_type_id: +this.selects.type.id,
        commercial_type_id: +this.selects.commercial.id,
        target_audience_id: +this.selects.target.id,
        name: this.selects.mediaPlan,
        taxes: this.selects.taxes,
        is_closed: this.selects.close,
        estimated_budget: +this.selects.budget,
        disallow_ejection: this.selects.type.name === 'Automatic' ? this.selects.denyPulling : false,
        is_one_commercial_in_block: this.selects.oneCommercialInBlock,
        is_budget_fixed: this.is_budget_fixed,
        fixed_budget: this.is_budget_fixed ? this.selects.fact : undefined,
        date_from: this.selects.start,
        date_to: this.selects.end,
        commercials: commercialsArray,
        discounts: this.canEditDiscounts ? discountsList : undefined,
        premium_position_discounts: this.canEditDiscounts ? this.selects.premiumPositions : undefined,
        co_branding_discount: this.co_branding_discount,
      };
      if (this.modalName === 'add') {
        await this.$store.dispatch('POST_MEDIA_PLANS', {
          formData,
          handler: () => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.addMediaPlans'),
              text: this.selects.mediaPlan,
            });
            this.clearFields();
            this.hideModalMediaPlan();
            this.$emit('refreshMediaPlans', 'restore');
          },
          handlerError: (errors) => {
            errorsHandler(errors, this.$notify);
          },
        });
      } else {
        const mediaPlanId = this.modalCurrentMediaPlan.id;
        await this.$store.dispatch('PUT_MEDIA_PLANS', {
          mediaPlanId,
          formData,
          handler: (res) => {
            //do not clear and close before automaton run
            if (!dontClearFields) {
              if (res?.warnings) {
                // show all warnings if exist (e.g. commercial settings can't be changed)
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.editMediaPlans'),
                  text: this.selects.mediaPlan,
                  duration: 3000,
                });
                for (const key in res.warnings) {
                  this.$notify({
                    type: 'warning',
                    title: key,
                    text: res.warnings[key],
                    duration: 3000,
                  });
                }
              } else {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.editMediaPlans'),
                  text: this.selects.mediaPlan,
                });
              }
              this.clearFields();
              this.hideModalMediaPlan();
              this.$emit('refreshMediaPlans', 'restore');
            }
            if (revertCloseState === true) {
              this.$notify({
                type: 'success',
                title: this.$i18n.t('table.open'),
                text: this.selects.mediaPlan,
              });
              if (res?.warnings) {
                for (const key in res.warnings) {
                  this.$notify({
                    type: 'warning',
                    title: key,
                    text: res.warnings[key],
                    duration: 3000,
                  });
                }
              }
              this.$emit('refreshMediaPlans', 'restore');
            }
          },
          handlerError: (errors) => {
            if (revertCloseState === true) this.selects.close = true;
            errorsHandler(errors, this.$notify);
          },
        });
      }
      this.hideModalMediaPlanConfirm();
      this.isModalBusy = false;
    },

    async deleteMediaPlans() {
      this.isModalBusy = true;
      const formData = this.modalCurrentMediaPlan.id;
      await this.$store.dispatch('DELETE_MEDIA_PLANS_ID', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.deleteMediaPlans'),
            text: this.modalCurrentMediaPlan.name,
          });
          this.$emit('refreshMediaPlans', 'restore-project-order');
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
          this.$emit('refreshMediaPlans', 'restore');
        },
      });
      this.hideModalDeleteMediaPlansConfirm();
      this.isModalBusy = false;
    },

    checkIfValidAddEditThenEnter() {
      if (!this.$v.$invalid) this.showModalMediaPlanConfirm();
    },

    showModalDoubleSpot(type, doubleSpotId) {
      this.modalDsType = type;
      if (doubleSpotId) {
        // Load double spots couple in list
        this.commercialsForModalDS = this.value.filter((el) => el.double_spot_id === doubleSpotId);
      } else {
        this.commercialsForModalDS = this.commercials?.data || [];
      }
      this.$bvModal.show('modal-double-spots');
    },

    syncDoubleSpots(doubleSpot, type) {
      if (type === 'add') {
        const parentCommercials = this.value.filter((el) => el.id === doubleSpot[0].id || el.id === doubleSpot[1].id);
        const parentCommercialsFromLeft = this.options.filter((el) => el.id === doubleSpot[0].id || el.id === doubleSpot[1].id);
        parentCommercials.forEach((parent) => {
          // Remove only single parent commercial from right list
          if (!parent.double_spot_id) this.value = this.value.filter((el) => el.id !== parent.id);
        });
        parentCommercialsFromLeft.forEach((parent) => {
          // Remove only single parent commercial from left list
          if (!parent.double_spot_id) this.options = this.options.filter((el) => el.id !== parent.id);
        });
      } else {
        //for edit
        this.value = this.value.filter((el) => el.id !== doubleSpot[0].id && el.id !== doubleSpot[1].id);
      }
      this.value.push(doubleSpot[0]);
      this.value.push(doubleSpot[1]);
    },

    addPeriod(index, comm) {
      this.commercialsSettings[index].settings.push({
        deviation: 0,
        grp: '',
        prime_percent: '',
        start_interval: this.BROADCAST_START_TIME_IN_SEC,
        end_interval: this.BROADCAST_START_TIME_IN_SEC + 24 * 60 * 60 - 1,
        date_start_at: this.selects.start,
        date_end_at: this.getMinDate(comm?.legal_before_at, this.selects.end),
      });
    },

    removeRow(index, index2) {
      this.commercialsSettings[index].settings.splice(index2, 1);
    },

    async runAutomaton(settings) {
      //save MP data before automaton run
      if (!this.$v.$invalid) await this.MediaPlan(true);
      else {
        this.$notify({
          type: 'error',
          title: this.$i18n.t('table.unableRunAutomaton'),
          text: this.$i18n.t('table.makeSureFieldsAreValid'),
          duration: 3000,
        });
        return;
      }
      this.automatonResult = null;
      this.isAutomatonCompleted = false;
      const formData = {
        automatics: [this.modalCurrentMediaPlan.id],
        settings: settings,
      };
      await this.$store.dispatch('POST_MEDIA_PLANS_AUTOMATON_EXECUTE', {
        formData,
        handler: (res) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.success'),
          });
          this.updateAfterAutomatonRun();
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
    },

    async updateAfterAutomatonRun() {
      await this.$store.dispatch('GET_MEDIA_PLANS_ID', {
        id: this.modalCurrentMediaPlan.id,
        data: { include: 'commercials,discounts,automaticMediaplanPrograms,premiumPositionDiscounts' },
      });
      this.calculateAutomatonResultTotals(this.modalEditMediaPlans?.automatic_info);
      this.isAutomatonCompleted = true;
      this.$emit('refreshMediaPlans', 'restore');
    },

    removeDiscount(id) {
      //remove discount from list after API delete
      this.discountsEditList = this.discountsEditList.filter((el) => el.id !== id);
    },

    async loadCommercialsList() {
      if (this.selects.brand) {
        await this.loadCommercialsListAPI();
        this.options = this.commercials?.data?.filter((el) => el.is_visible) || [];
      } else {
        this.options = [];
      }
      this.value = [];
      this.commercialsSettings = [];
    },

    async loadCommercialsListAPI() {
      if (this.selects.brand) {
        await getCommercials({
          'filter[brand_id]': this.selects.brand?.id,
          'filter[commercial_type_id]': this.selects.commercial?.id,
          'filter[is_visible]': true,
          include: 'commercialVersionType,brand,coBrand,commercialType',
          per_page: 2000,
        })
          .then((res) => (this.commercials = res.data))
          .catch((err) => (this.commercials = null));
      }
    },

    calculateAutomatonResultTotals(data) {
      if (!data) return;
      this.automatonResult = {
        data: {},
        totals: {
          grp: 0,
          wgrp: 0,
          total_price: 0,

          prime_grp: 0,
          prime_wgrp: 0,
          prime_price: 0,

          off_prime_grp: 0,
          off_prime_wgrp: 0,
          off_prime_price: 0,

          spot_count_prime: 0,
          spot_count_off_prime: 0,
        },
      };
      Object.entries(data).forEach(([key, val]) => {
        this.automatonResult.data[key] = {
          data: val,
          totals: {},
        };

        // Count comercial totals of all its settings
        this.automatonResult.data[key].totals.grp = val.reduce((total, current) => total + (+current.grp || 0), 0);
        this.automatonResult.data[key].totals.wgrp = val.reduce((total, current) => total + (+current.wgrp || 0), 0);
        this.automatonResult.data[key].totals.total_price = val.reduce((total, current) => total + (+current.total_price || 0), 0);
        this.automatonResult.data[key].totals.prime_grp = val.reduce((total, current) => total + (+current.prime_grp || 0), 0);
        this.automatonResult.data[key].totals.prime_wgrp = val.reduce((total, current) => total + (+current.prime_wgrp || 0), 0);
        this.automatonResult.data[key].totals.prime_price = val.reduce((total, current) => total + (+current.prime_price || 0), 0);
        this.automatonResult.data[key].totals.off_prime_grp = val.reduce((total, current) => total + (+current.off_prime_grp || 0), 0);
        this.automatonResult.data[key].totals.off_prime_wgrp = val.reduce((total, current) => total + (+current.off_prime_wgrp || 0), 0);
        this.automatonResult.data[key].totals.off_prime_price = val.reduce((total, current) => total + (+current.off_prime_price || 0), 0);
        this.automatonResult.data[key].totals.spot_count_prime = val.reduce((total, current) => total + (+current.spot_count_prime || 0), 0);
        this.automatonResult.data[key].totals.spot_count_off_prime = val.reduce((total, current) => total + (+current.spot_count_off_prime || 0), 0);

        // Count general totals
        this.automatonResult.totals.grp += +this.automatonResult.data[key].totals.grp || 0;
        this.automatonResult.totals.wgrp += +this.automatonResult.data[key].totals.wgrp || 0;
        this.automatonResult.totals.total_price += +this.automatonResult.data[key].totals.total_price || 0;
        this.automatonResult.totals.prime_grp += +this.automatonResult.data[key].totals.prime_grp || 0;
        this.automatonResult.totals.prime_wgrp += +this.automatonResult.data[key].totals.prime_wgrp || 0;
        this.automatonResult.totals.prime_price += +this.automatonResult.data[key].totals.prime_price || 0;
        this.automatonResult.totals.off_prime_grp += +this.automatonResult.data[key].totals.off_prime_grp || 0;
        this.automatonResult.totals.off_prime_wgrp += +this.automatonResult.data[key].totals.off_prime_wgrp || 0;
        this.automatonResult.totals.off_prime_price += +this.automatonResult.data[key].totals.off_prime_price || 0;
        this.automatonResult.totals.spot_count_prime += +this.automatonResult.data[key].totals.spot_count_prime || 0;
        this.automatonResult.totals.spot_count_off_prime += +this.automatonResult.data[key].totals.spot_count_off_prime || 0;
      });
    },

    async changeCloseMPState(value) {
      //show confirmation modal for opening MP in edit mode
      if (!this.$v.$invalid && this.modalName === 'edit' && this.canEdit && this.modalEditMediaPlans?.is_closed === true) {
        const modalResult = await this.$bvModal
          .msgBoxConfirm(`${this.$i18n.t('table.confirmOpen')} ${this.$i18n.t('table.mediaPlan')} ${this.selects.mediaPlan}?`, {
            title: this.$i18n.t('table.openVerb') + ' ' + this.$i18n.t('table.mediaPlan'),
            size: 'sm',
            okTitle: this.$i18n.t('table.yes'),
            cancelTitle: this.$i18n.t('table.no'),
            autoFocusButton: 'ok',
            id: 'confirm-open-mp-modal',
          })
          .catch((err) => {});
        if (!modalResult) {
          this.selects.close = true;
          return;
        }

        await this.MediaPlan(true, true);
      }
    },
  },
};
</script>

<style lang="sass" src="../../assets/sass/bookingCommercialsTable.sass"></style>
<style lang="sass">
#automatonTableSettings
  .close-table-day-picker
    width: 120px

  svg.trash-hover
    font-size: 1rem

[data-color-scheme="dark"] #modal-media-plan
  .custom-control.custom-switch
    border-color: hsl(222deg, 14%, 30%) !important
  .bg-dark-lighter
    background-color: #383e4c !important
</style>
