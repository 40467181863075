<template>
  <b-modal id="modal-automatic" hide-footer :title="$t('table.runAutomaton')" size="md">
    <b-form-group :label="$t('automatic.dateInterval')">
      <div class="d-flex flex-fill">
        <datepicker-wrapper v-model="date_start_at" required :min="minDate" :max="maxDate" class="w-50" />
        <datepicker-wrapper v-model="date_end_at" required :min="minDate" :max="maxDate" class="w-50 flex-shrink-0" />
      </div>
    </b-form-group>
    <b-form-group :label="$t('automatic.timeInterval')">
      <div class="d-flex flex-fill">
        <BroadcastTimePicker id="input-3" v-model="settings.start_interval" required :interval-mode="true" />
        <BroadcastTimePicker id="input-4" v-model="settings.end_interval" required :interval-mode="true" />
      </div>
    </b-form-group>

    <fieldset class="border border-danger p-3 rounded-sm mb-3">
      <b-form-group :label="$t('automatic.limitByAdvertiser')" label-for="input-5">
        <input
          id="input-5"
          v-model="settings.limit_by_advertiser"
          class="form-control"
          type="number"
          step="1"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
          min="0"
        />
      </b-form-group>
      <b-form-group :label="$t('automatic.limitByBrand')" label-for="input-5">
        <input
          id="input-5"
          v-model="settings.limit_by_brand"
          class="form-control"
          type="number"
          step="1"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
          min="0"
        />
      </b-form-group>
      <b-form-group :label="$t('automatic.limitByCommercial')" label-for="input-5">
        <input
          id="input-5"
          v-model="settings.limit_by_commercial"
          class="form-control"
          type="number"
          step="1"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
          min="0"
        />
      </b-form-group>
      <b-form-group>
        <b-form-checkbox v-model="settings.count_non_automatics">
          {{ $t('automatic.includeNonAutomatics') }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox v-model="settings.include_auction_blocks">
          {{ $t('auction.includeAuctionBlocks') }}
        </b-form-checkbox>
      </b-form-group>
    </fieldset>

    <div class="d-flex gap-3">
      <BButtonEnh variant="primary" @click="runAutomaton()">
        {{ $t('table.go') }}
      </BButtonEnh>
      <BButtonEnh variant="primary" @click="runAutomaton(true)">
        {{ $t('table.rePlacement') }}
      </BButtonEnh>
    </div>
  </b-modal>
</template>

<script>
import BButtonEnh from '@/components/BButtonEnh.vue';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import BroadcastTimePicker from '../BroadcastTimePicker.vue';
export default {
  name: 'ModalAutomatic',
  components: {
    BButtonEnh,
    DatepickerWrapper,
    BroadcastTimePicker,
  },
  props: {
    minDate: { type: String, default: undefined },
    maxDate: { type: String, default: undefined },
    storageKey: { type: String, required: true },
    startDate: { type: String, default: undefined },
    endDate: { type: String, default: undefined },
  },
  data() {
    return {
      clear: null,
      date_start_at: this.startDate,
      date_end_at: this.endDate,
      settings: {
        end_interval: 107999,
        start_interval: 21600,
        limit_by_commercial: process.env.VUE_APP_COUNTRY_CODE == 'UZ' ? 1 : '',
        limit_by_brand: process.env.VUE_APP_COUNTRY_CODE == 'UZ' ? 1 : '',
        limit_by_advertiser: process.env.VUE_APP_COUNTRY_CODE == 'UZ' ? 1 : '',
        count_non_automatics: false,
        include_auction_blocks: false,
      },
    };
  },
  computed: {},
  watch: {
    settings: {
      handler(newSettings) {
        localStorage.setItem(this.storageKey, JSON.stringify(newSettings));
      },
      deep: true,
    },
    startDate: {
      handler(date) {
        this.date_start_at = date;
      },
    },
    endDate: {
      handler(date) {
        this.date_end_at = date;
      },
    },
  },
  mounted() {
    if (localStorage.getItem(this.storageKey) != null) {
      let settings = JSON.parse(localStorage.getItem(this.storageKey));
      if (Object.keys(settings).length == Object.keys(this.settings).length) {
        this.settings = settings;
      }
    }
  },
  methods: {
    runAutomaton(clear) {
      let settings = {};
      settings.clear = clear;
      settings.date_start_at = this.date_start_at;
      settings.date_end_at = this.date_end_at;
      settings.start_interval = this.settings.start_interval;
      settings.duration = this.settings.end_interval - this.settings.start_interval;
      settings.count_non_automatics = this.settings.count_non_automatics;
      settings.include_auction_blocks = this.settings.include_auction_blocks;
      if (this.settings.limit_by_commercial) settings.limit_by_commercial = this.settings.limit_by_commercial;
      if (this.settings.limit_by_brand) settings.limit_by_brand = this.settings.limit_by_brand;
      if (this.settings.limit_by_advertiser) settings.limit_by_advertiser = this.settings.limit_by_advertiser;
      this.$root.$emit('bv::hide::modal', 'modal-automatic');
      this.$emit('run-automaton', settings);
    },
  },
};
</script>
